<template>
  <!--涉及到多级路由参数-->
  <div class="page">

    <!--工具按钮集-->
    <div class="button-bar">
      <el-button
          type="primary"
          @click="submit()"
          :plain="true"
          size="mini"
          :disabled="ui.submitDisabled">
        <i class="fa fa-save i-gap"></i><span>立即确认</span>
        <span
            v-if="ui.count!=6 && ui.count!==0"
            class="timer"> {{ui.count}}
        </span>
      </el-button>
      <el-button
          type="warning"
          @click="resetForm('form')"
          :plain="true" size="mini">
        <i class="fa fa-undo"></i> 重置
      </el-button>
    </div>

    <!--表单滚动区域-->
    <div class="form-scroll">

      <el-form ref="form" :model="form" :label-width="ui.labelWidth">

        <el-form-item
            label="旧密码"
            :required="true"
            :show-message="false"
            prop="password">
          <el-input
              v-model="form['password']"
              type="password"
              placeholder="请输入旧密码"
              autocomplete="off"
              auto-complete="new-password"
              clearable>
          </el-input>
          <div class="fa-icon">
            <i class="fa-icon fa fa-eye"></i>
          </div>
        </el-form-item>

        <el-form-item
            label="新密码"
            :required="true"
            :show-message="false"
            prop="new_password">
          <el-input
              v-model="form['new_password']"
              type="password"
              placeholder="请输入新密码"
              autocomplete="off"
              auto-complete="new-password"
              clearable>
          </el-input>
          <div class="fa-icon">
            <i class="fa-icon fa fa-eye"></i>
          </div>
        </el-form-item>

        <el-form-item
            label="再次确认"
            :required="true"
            :show-message="false"
            prop="confirm_password">
          <el-input
              v-model="form['confirm_password']"
              type="password"
              placeholder="请输入确认密码"
              autocomplete="off"
              auto-complete="new-password"
              clearable>
          </el-input>
          <div class="fa-icon">
            <i class="fa-icon fa fa-eye"></i>
          </div>
        </el-form-item>

        <!--占位-->
        <el-form-item v-show="ui.placeHolder" class="place-holder">
        </el-form-item>

      </el-form>
    </div>


  </div>
</template>

<script>

import {updatePwd} from '@/api/public.js'
import eventBus from '../../static/js/eventBus.js';

export default {
  name: "updatePwd",
  data() {
    return {

      // 界面参数
      ui: {
        labelWidth: '120px',
        placeHolder: false,
        submitDisabled: false,
        count: 6,
      },

      // 输入参数
      caption: {
        tableName: 'wl_user',
        tableAlias: 'wluser',
        tableTitle: '编辑资料',
        keyIdName: 'id',
        id: '',
        title: '编辑',
      },

      // 表单数据
      form: {},

    }
  },
  created() {

    // 初始化
    this.initParm();
  },
  mounted() {

    // 检测窗体变化
    window.onresize = () => {
      return (() => {
        this.setResize();
      })()
    }

  },
  beforeUpdate() {

  },
  updated() {
    let element = document.querySelectorAll('.el-form-item:not(.place-holder)');
    if ((element.length % 2) == 1) {
      this.ui.placeHolder = true;
    }
  },
  activated() {

    eventBus.$off('reback');
    eventBus.$on('reback', (data) => {
      if (data['act'] == 'selectData') {
        this.setSelect(data)
      }
    });

  },
  beforeDestroy() {
    // 组件销毁时清除计时器
    clearInterval(this.timer);
  },
  methods: {
    // 初始化参数
    initParm() {
      this.setResize();
    },

    // 初始化数据格式
    initCaption() {

    },

    // 初始化数据
    initData() {
    },

    // 检测窗体变化
    setResize() {
      this.$nextTick(() => {
        let width = document.documentElement.clientWidth;
        if (width < 768) {
          this.ui.labelWidth = '80px';
        } else {
          this.ui.labelWidth = '120px';
        }
      });
    },

    // 提交
    submit() {

      // 倒计时
      this.ui.submitDisabled = true;

      let title = '信息提示';
      let type = 'warning';
      let check = !this.form["password"] || !this.form["confirm_password"] || !this.form["new_password"]

      if(check){
        this.$message({type: type, message: '输入框均不能为空！'});
        this.ui.submitDisabled = false;
        return;
      }

      updatePwd(this.form).then(res => {

        if (res.result) {
          let message = "密码修改成功，请重新登录！";
          this.$alert(message, title, {
            confirmButtonText: '确定',
            callback: action => {
              this.$message({type: type, message: message});
            }
          });
        } else {
          this.$alert(res.message, title);
          this.$message({type: type, message: res.message});
        }

      });

      // 开启倒计时
      this.startTimer();
    },

    // 倒计时
    startTimer() {
      this.timer = setInterval(() => {
        if (this.ui.count > 0) {
          this.ui.count--;
        } else {
          clearInterval(this.timer); // 倒计时结束，停止计时器
          this.ui.submitDisabled = false;
          this.ui.count = 6;
        }
      }, 1000);
    },

    // 重置表单
    resetForm(formName) {

      this.form = [];
      this.$forceUpdate();
    },
  },
}
</script>

<style lang="scss">

/*--基础+加载动画--*/
html, body, #app, .page {

  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {

  animation: page-fade-in 1s forwards;
  background: url(../../static/images/bodyback.jpg);
}

@keyframes page-fade-in {
  0% {
    opacity: 0
  }
  100% {
    opacity: 1
  }
}

// 滚动条样式
#app .form-scroll::-webkit-scrollbar {
  border-radius: 10px;
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}

#app .form-scroll::-webkit-scrollbar-thumb {
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, .3);
  background-color: rgba(153, 153, 153, 0.05);

  background-image: url(../../static/images/icon.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px;
}

/*--按钮栏--*/
.button-bar {

  margin: 10px;
  padding: 0px 5px;
  height: 42px;
  line-height: 40px;

  background: #FFF;
  border-radius: 6px;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;

  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);

  .timer {

    margin-left: 5px;
    font-size: 12px;
    color: #dd524d;
    text-align: center;
  }
}

/*--表单滚动栏--*/
.form-scroll {

  margin: 0px 10px;
  padding: 40px 20px;

  border-radius: 6px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
  background-color: #FFF;

  height: calc(100% - 150px);

  overflow-y: auto;

  background-image: url(../../static/images/bg_1.png);
  background-repeat: no-repeat;
  background-position: right bottom;

  .fa-icon {
    position: absolute;
    z-index: 1;
    top: 0px;
    right: -1px;

    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #000;
    opacity: 0.4;

    pointer-events: none;
  }

  .el-form-item {

    margin-bottom: 5px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid #f0f0f0;

    /*--2行--*/
    float: left;
    width: 50%;

    .el-form-item__label {
      white-space: nowrap;
      word-break: break-all;
      overflow: auto;
    }

  }

  textarea {
    position: absolute;
    z-index: 100;
    top: -40px;
    height: 40px;
  }

  textarea:focus {
    height: 105px;
  }

  .el-input__inner {
    padding-right: 35px;
  }

}

/*--alert弹框--*/
.el-message-box {
  max-width: 80%;
}

.i-gap {
  margin-right: 5px;
}

@media (max-width: 768px) {

  .el-form-item {
    float: none !important;
    width: auto !important;
  }
  .place-holder {
    display: none;
  }

}

</style>